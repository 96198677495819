import React from "react";
import { useSnapshot } from "valtio";
import { useNavigate } from "react-router-dom";

// Data and State
import {
  GlobalStore,
  citiesData,
  increaseHomeing
} from "@data";

// Components
import { Logo } from "@icons";
import SearchBar from "./SearchBar";


// HEADER
export const Header = ({link}) => {
  const { activeCity, searchInputOpen, cookies, mapLoading } = useSnapshot(GlobalStore);
  const navigate = useNavigate();

  function handleNavigate() {
    navigate("/");
  }

  return (
    <div className={"header " + (searchInputOpen ? "searching" : "")} >

      <div className={"header__brand " + (mapLoading ? "loading" : "")} >
        <button
          className="header__brand-logo"
          aria-label="Napenergiatérkép"
          onClick={link ? handleNavigate : increaseHomeing}
        >
          <Logo />
        </button>
        {activeCity !== null &&
          <div className="header__brand-city">
            <h4>{citiesData[activeCity].name}</h4>
          </div>
        }
      </div>
      {cookies && !link && <SearchBar/>}
    </div>
  );
};