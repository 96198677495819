import { proxy } from "valtio";

// Components
import MiniSolarImage from "./../assets/images/MiniSolarImage.png";
import MiniOrtoImage from "./../assets/images/MiniOrtoImage.png";

// STATE OBJECT
export const GlobalStore = proxy({
  cookies: null,
  welcomeModal: true,
  onboardingStage: 0, // 0: Initial, 1: Zoomed, 2: Clicked (Finished)

  subscribed: {
    email: null,
    cities: [],
  },

  activeCity: null,

  switchableLayers: [
    { name: "Szolár", url: "solarURL", miniMap: MiniSolarImage },
    { name: "Légifotó", url: "ortoURL", miniMap: MiniOrtoImage }
  ],
  activeSwitchableLayer: 0,
  selectedBuilding: null,
  selectedBuildingAddress: null,

  mapHomeing: 0,
  
  searchInputOpen: false,
  searchMobileOpen: false,
  searchBounds: [],

  isAdmin: false,

  mapLoading: false,
});

// Cookie
export const setCookies = (value) => {
  GlobalStore.cookies = value;
};

// WelcomeModal
export const setWelcomeModal = () => {
  GlobalStore.welcomeModal = false;
};

// Onboarding
export const setOnboardingStage = (stage) => {
  GlobalStore.onboardingStage = stage;
};

// Subscribed cities
export const setSubscribed = (data) => {
  GlobalStore.subscribed = data;
};

// Map functions
export const setActiveCity = (id) => {
  GlobalStore.activeCity = id;
};

export const setActiveLayer = (id) => {
  GlobalStore.activeSwitchableLayer = id;
};

export const setBuilding = (feature) => {
  GlobalStore.selectedBuilding = feature;
};

export const setBuildingAddress = (address) => {
  GlobalStore.selectedBuildingAddress = address;
};

export const unsetBuilding = () => {
  GlobalStore.selectedBuilding = null;
  GlobalStore.selectedBuildingAddress = null;
};

// Map homeing
export const increaseHomeing = () => {
  GlobalStore.mapHomeing += 1;
};


// Search functions
export const setSearchInput = (value) => {
  GlobalStore.searchInputOpen = value;
};

export const setSearchMobileOpen = (open) => {
  if (open) {
    GlobalStore.searchMobileOpen = true;
  } else {
    GlobalStore.searchMobileOpen = false;
  }
};

export const setSearchBounds = (SWLng, SWLat, NELng, NELat) => {
  GlobalStore.searchBounds = NELng ? [SWLng, SWLat, NELng, NELat] : [SWLng, SWLat];
};

// Mobile search functions 
export const setMobileSearchOpen = (open) => {
  if (open) {
    GlobalStore.mobileMenuOpen = true;
  } else {
    GlobalStore.mobileMenuOpen = false;
  }
};

// Admin
export const setIsAdmin = (value) => {
  GlobalStore.isAdmin = value;
};

// Map loading
export const setMapLoading = (value) => {
  GlobalStore.mapLoading = value;
};