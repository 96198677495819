import React, { useEffect, useRef, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import { useSnapshot } from "valtio";

// State
import {
  GlobalStore,
  setSearchInput,
  setSearchMobileOpen,
  setSearchBounds,
  citiesData
} from "@data";

// Components
import { Search, ArrowRight, Close } from "@icons";


// Search settings
const SearchSettings = {
  libraries: ["places"],
  requestOptions: {
    types: ["address"],
    componentRestrictions: {
      country: "hu"
    },
  },
};


// SEARCHBAR - GM API wrapper
const SearchBar = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    libraries: SearchSettings.libraries,
  });
  return !isLoaded || loadError ? null : <SearchBarComponent />;
};


// SEARCHBAR COMPONENT
const SearchBarComponent = () => {
  const { activeCity, searchMobileOpen, searchInputOpen } = useSnapshot(GlobalStore);
  const [searchCity, setSearchCity] = useState(null);
  const [searchText, setSearchText] = useState(null);

  const inputRef = useRef();

  // Send search request
  const FormSubmitHandler = async (e) => {
    e.preventDefault();

    // If search input has value and isn't open
    if (searchText !== "" && searchText !== null && searchInputOpen) {
      // Once the search request has been sent, set
      // the value to selected, display the location
      // and close the suggestions.
      setValue(searchText, false);
      setSearchText(searchText);
      clearSuggestions();
      // Try...catch statement: the entered location
      // is sent to the geocode, from where we get back
      // lat and lng data, which the code uses to
      // coordinate us to the correct location.
      try {
        const results = await getGeocode({
          address: searchText,
        });

        const { geometry } = results[0];
        const { bounds, location } = geometry;

        if (bounds) {
          const NELat = bounds.getNorthEast().lat();
          const NELng = bounds.getNorthEast().lng();
          const SWLat = bounds.getSouthWest().lat();
          const SWLng = bounds.getSouthWest().lng();
          setSearchBounds(SWLng, SWLat, NELng, NELat);
        } else {
          const CLat = location.lat();
          const CLng = location.lng();
          setSearchBounds(CLat, CLng);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Open search bar
  const SearchBarHandler = () => {
    // Open search bar if it's closed
    if (!searchInputOpen) {
      setValue(searchCity ? searchCity + ", " : "", false);
      setSearchInput(true);
      inputRef.current.focus();
    }
  };

  // Clear and close search bar
  const SearchBarDeleteHandler = () => {
    // If the search input contains no character(s)
    if (searchText === "" || searchText === null) {
      setSearchInput(false);
      if (searchMobileOpen) {
        setSearchMobileOpen(false);
      }
    }
    // If search input contains character(s)
    if (searchText !== "") {
      setValue(searchCity ? searchCity + ", " : "", false);
      setSearchText("");
    }
  };

  // Handle activeCity changes
  useEffect(() => {
    // Close search input
    setSearchInput(false);
    // Change search filtering
    setSearchCity(
      activeCity !== null
        ? citiesData[activeCity].name
        : null
    );
  }, [activeCity]);

  
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: SearchSettings.requestOptions,
  });

  return (
    <div className={"header__search " + (searchInputOpen ? "active" : "")}>
      
      <form onSubmit={FormSubmitHandler}>
        <label>
          <button
            type="submit"
            aria-label="Keresés"
            onClick={() => SearchBarHandler()}
          >
            <Search />
          </button>
          <input
            ref={inputRef}
            type="text"
            placeholder="Keresés..."
            onChange={(e) => {
              setSearchText(e.target.value);
              setValue(e.target.value);
            }}
            value={value}
            disabled={!ready}
          />

          <ul className="header__search__list">
            {status === "OK" &&
              data.map((search) => {
                return (
                  <button
                    type="submit"
                    onClick={() => {
                      setSearchText(search.description);
                    }}
                    key={search.place_id}
                  >
                    {search.description}
                    <ArrowRight />
                  </button>
                );
              })}
          </ul>

          <button onClick={() => SearchBarDeleteHandler()}>
            <Close />
          </button>
        </label>
      </form>
    </div>
  );
};

export default SearchBar;
