/*
  // Clear Data Effect
  useEffect(() => {
    console.log("---");
    let citiesDataCleared = [];

    citiesData.forEach(city => {
      citiesDataCleared.push(clearData(city));
    });

    console.log(citiesDataCleared);
  }, []);
*/

import { transformExtent } from "ol/proj";

export const clearData = (city) => {
  const fC = flattenPolygonCoordinates(city);
  const rP = roundPolygonCoordinates(fC, 6);
  const extent = generateExtentFromPolygon(rP);
  const center = calculateCenterFromExtent(extent);

  const cityData = {
    name: city.name,
    slug: city.slug,
    available: city.available,
    availableNote: city.availableNote,
    center: center,
    extent: extent,
    polygon: rP,
    solarURL: city.solarURL,
    ortoURL: city.ortoURL,
    buildingFootprintLayer: city.buildingFootprintLayer,
  };
  return cityData;
};

export const round = (n,d) => {
  return parseFloat(n).toFixed(d);
};

export const flattenPolygonCoordinates = (city) => {
  const { name, polygon } = city;

  function getArrayDepth(arr) {
    return Array.isArray(arr)
      ? 1 + Math.max(0, ...arr.map(getArrayDepth))
      : 0;
  }

  function flattenAtDepth(array, depth = 3) {
    const arrayDepth = getArrayDepth(array);
    if (arrayDepth <= depth) {
      return array;
    }
    return array.reduce((result, element) => {
      if (Array.isArray(element) && getArrayDepth(element) === depth) {
        return result.concat(element);
      }
      if (Array.isArray(element)) {
        return result.concat(flattenAtDepth(element, depth));
      }
      return result.concat(element);
    }, []);
  }

  const arrayDepth = getArrayDepth(polygon);
  const flattenPolygon = arrayDepth <= 3
    ? polygon
    : arrayDepth === 4
      ? flattenAtDepth(polygon)
      : console.log("Deep feature!", arrayDepth, name);
  
  return flattenPolygon;
};

export const roundPolygonCoordinates = (polygon, rounding) => {
  const roundedPolygon = polygon.map(poly => 
    poly.map(coords => 
      coords.map(c => parseFloat(c.toFixed(rounding)))
    )
  );
  return roundedPolygon;
};

export const generateExtentFromPolygon = (polygon) => {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  polygon.forEach(loop => {
    loop.forEach(point => {
      if (point[0] < minX) minX = point[0];
      if (point[0] > maxX) maxX = point[0];
      if (point[1] < minY) minY = point[1];
      if (point[1] > maxY) maxY = point[1];
    });
  });

  return [minX, minY, maxX, maxY];
};

export const calculateCenterFromExtent = (extent) => {
  let latC = (extent[0] + extent[2]) / 2;
  let lonC = (extent[1] + extent[3]) / 2;
  return [latC, lonC];
};

export const generateExtentFromRadius = (c, r) => {
  const earthRadius = 6371;
  const latRadian = c[1] * Math.PI / 180;
  const degLatKm = earthRadius * Math.PI / 180;
  const degLonKm = degLatKm * Math.cos(latRadian);
  const dLat = r / degLatKm;
  const dLon = r / degLonKm;
  return [c[0] - dLon, c[1] - dLat, c[0] + dLon, c[1] + dLat];
};

export const getExtentCenter = (e) => {
  const tE = transformExtent(e, 'EPSG:3857', 'EPSG:4326');
  const c = [
    (tE[0] + tE[2]) / 2,
    (tE[1] + tE[3]) / 2
  ];
  return c;
};