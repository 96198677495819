import React, { useRef, useState, useEffect } from "react";
import { useSnapshot } from "valtio";
import ReactGA from "react-ga4";

// State
import {
  GlobalStore,
  citiesData,
  unsetBuilding,
} from "@data";

// Components
import { Electricity, Saving } from "@icons";
import { ButtonClose } from "@molecules";
import UpsellBox from "./UpsellBox";
import { getGeocode } from "@api";
import { getExtentCenter } from "@utils";


// PANEL
export const BuildingPanel = () => {
  const { selectedBuilding, activeCity } = useSnapshot(GlobalStore);
  const panelRef = useRef();

  const [ data, setData ] = useState({});

  useEffect(() => {
    if (selectedBuilding !== null) {

      // Get address for selected building
      const extentCenter = getExtentCenter(selectedBuilding.mergedExtent);
      getGeocode(extentCenter[1], extentCenter[0]);

      // Set panel data from feature
      setData({
        produce: selectedBuilding.feature.get('A_Menny_Fi'),
        saving: selectedBuilding.feature.get('Ft_Vegso')
      });

      // Send a custom event
      ReactGA.event({
        category: "Building",
        action: "Selected",
        label: citiesData[activeCity].name,
      });
    } else {
      setData({});
    }
  }, [selectedBuilding]);

  return (
    <aside
      ref={panelRef}
      className={"panel " + (selectedBuilding === null ? "collapsed" : "")}
    >
      <div className="panel__infobox">

        <div className="panel__infobox__header">
          <div>
            <h3>Napelemek telepítésével</h3>
          </div>
          <ButtonClose
            action={unsetBuilding}
            title="Panel bezárása"
          />
        </div>

        <div className="panel__infobox__infoblock">
          <InfoBlock
            icon={<Electricity/>}
            value={data.produce}
            metric="kWh/év"
            label="Megtermelhető éves villamosenergia becsült mennyisége*"
          />
          <InfoBlock
            icon={<Saving/>}
            value={data.saving}
            metric="Ft/év"
            label="Elérhető megtakarítás*"
          />
        </div>

        <span className="panel__infobox__disclaimer">
          * A feltüntetett adatok az Envirosense Hungary Kft. által végzett légi felmérések és besugárzási adatok elemzésével jöttek létre, melyek a tetőfelületre számított napelemes rendszerre vonatkozó potenciálértékek, így nem veszik figyelembe a megtermelt villamosenergia tárolásának és szolgáltatói elszámolásának módját. Az elérhető információk tájékoztató jellegűek!
        </span>
      </div>

      <UpsellBox/>

    </aside>
  );
};

const InfoBlock = (props) => {

  const formatNumber = (number) => {
    return number !== undefined ? number.toLocaleString('hu-HU') : null;
  };

  return (
    <div className="infoblock__item">
      <div className="infoblock__item__icon">
        {props.icon}
      </div>
      <div className="infoblock__item__content">
        <div className="value">
          <h1>{formatNumber(Math.floor(props.value))}</h1>
          <p>{props.metric}</p>
        </div>
        <span className="span--bold">{props.label}</span>
      </div>
    </div>
  );
};