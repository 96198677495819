import React from "react";
import { useSnapshot } from "valtio";

// State
import { GlobalStore, citiesData } from "@data";


// MAP FOOTER
export const MapFooter = () => {
  const { activeCity, activeSwitchableLayer, isAdmin } = useSnapshot(GlobalStore);

  return (
    <div className="map-footer">
      <Copyright/>
      {(activeCity !== null && activeSwitchableLayer === 0 && (citiesData[activeCity]?.available || isAdmin))
        ? <Legend />
        : null
      }
    </div>
  );
};

const Copyright = () => {
  return (
    <div className="map-footer__copyright">
      <p className="attribution">
        {new Date().getFullYear()} - © Solar Viewpoint Kft.
      </p>
      <a
        className="legal"
        href="/Honlap_Hasznalati_Feltetelek-latest.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Honlap Használati Feltételek
      </a>
    </div>
  );
};

const Legend = () => {
  return (
    <div className="map-footer__legend">
      <p className="value">
        0 kWh/m²/év
      </p>
      <div className="line"/>
      <p className="value">
        1200 kWh/m²/év
      </p>
    </div>
  );
};